export default {
	name: 'SignTypeChose',
	data() {
		return {
			cardInfo: [
				{
					imgUrl: require('@/assets/sign1.png'),
					typeName: '机构会员',
					summary: '保障临床研究项目质量、掌握CRC所负责的项目情况、量化CRC工作量，全方位评估工作能力',
				},
				{
					imgUrl: require('@/assets/sign2.png'),
					typeName: '企业会员',
					summary: '快速提升CRC整体业务能力、高效率管理CRC日常工作、增加企业经济回报效益',
				},
				{
					imgUrl: require('@/assets/sign3.png'),
					typeName: '带教老师',
					summary: '进行统一培训、颁发资质证书，获得培训费用',
				},
			],
			// typeList: ['mechanism', 'compony', 'teacher'],
		};
	},
	computed: {
		regType() {
			return this.$root.userInfo.type
		},
		isPass() {
			return this.$root.userInfo.isPass
		}
	},
	methods: {
		checkIsEnable(index) {
			let type = this.regType;
			let isEnable = false
			if (!type) {
				isEnable = true;
			} else {
				isEnable = ((index + 1) === type && this.isPass == 2)
			}
			return isEnable
		},

		jumpTo: function (index) {
			if (this.checkIsEnable(index)) {
				this.$router.push(`/reg/${index + 3}`)
			}
		}
	},
}
